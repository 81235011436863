import React, { ReactNode, useEffect } from 'react';
import ReactFocusLock from 'react-focus-lock';
import styled, { DefaultTheme, css } from 'styled-components';

import useKeyPress from '../../../../hooks/useKeyPress';
import TaskTracking, { HeapModalName } from '../../../../types/TaskTracking';
import Portal from '../../../application/Portal';
import { mediaBreakpointPxSm } from '../../../styled/Breakpoint';
import IconButton from '../../buttons/IconButton';
import { ModalSize } from './CoreModalTypes';

export interface Props extends TaskTracking {
  visible?: boolean;
  onCloseRequest: () => void;
  showClose?: boolean;
  children?: ReactNode[] | ReactNode;
  desktopSize?: ModalSize;
  isStaticModal?: boolean;
  isBlurShadedBackdrop?: boolean;
  disabledPadding?: DisabledPadding;
  closeIconAriaLabel: string;
  headerImage?: string;
}

const ModalContainer = styled.div<{ headerImage?: string }>`
  background: ${({ theme: { vars } }) => vars.foundationSurface1};
  box-shadow: ${({ theme: { vars } }) => vars.shadowTopSmall};
  border: ${({ theme: { constants, vars } }) =>
    `${constants.borderWidthSm} solid ${vars.borderSurface1}`};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  width: 100%;
`;

const HeaderImage = styled.div<{ headerImage: string }>(
  ({ theme: { constants } }) => css`
    width: 100%;
    min-height: 10rem;
    margin-bottom: ${constants.spacerMd1};
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    overflow: hidden;

    img {
      max-height: 13rem;
      width: 100%;
      object-fit: cover;
      object-position: bottom;
    }
  `
);

const RightAlignedContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  padding: ${({ theme: { constants } }) => `${constants.spacerMd2} ${constants.spacerMd2} 0 0`};
`;

export const CORE_MODAL_BACKDROP_Z_INDEX = 1060;
const Backdrop = styled.div<{ visible: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  z-index: ${CORE_MODAL_BACKDROP_Z_INDEX};
  height: 100vh;
  width: 100vw;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`;

const ShadedBackdrop = styled(Backdrop)`
  background-color: ${({ theme: { vars } }) => vars.shadowBackground3};
  backdrop-filter: blur(0.75px);
`;

const ShadedBlurBackdrop = styled(Backdrop)`
  backdrop-filter: blur(4px);
`;

const ContentBackdrop = styled(Backdrop)`
  height: 100%;
  overflow: hidden auto;
  width: 100%;
`;

const Dialog = styled.div<{ size: ModalSize }>`
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: ${({ size }) => modalContainerWidth(size)};
  position: relative;
`;

const Padded = styled.div`
  padding: 1.75em 1rem;

  @media (min-width: ${mediaBreakpointPxSm}) {
    padding: 1.75em 0;
  }
`;

const PaddedIcon = styled(IconButton)<{ headerImage?: string }>`
  color: ${({ headerImage, theme: { vars } }) =>
    headerImage ? vars.textSurface : vars.textDefault};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  font-size: 1.75rem;
  &:hover {
    color: ${({ headerImage, theme: { vars } }) =>
      headerImage ? vars.textSurface : vars.textDefault};
  }
`;

const PaddedContainer = styled.div<{ size: ModalSize; disabledPadding?: DisabledPadding }>`
  ${({ disabledPadding, theme }) => {
    const topDisabled = disabledPadding?.vertical?.all || disabledPadding?.vertical?.top;
    const bottomDisabled = disabledPadding?.vertical?.all || disabledPadding?.vertical?.bottom;
    const leftDisabled = disabledPadding?.horizontal?.all || disabledPadding?.horizontal?.left;
    const rightDisabled = disabledPadding?.horizontal?.all || disabledPadding?.horizontal?.right;

    return {
      paddingTop: topDisabled ? '0' : theme.constants.spacerMd2,
      paddingBottom: bottomDisabled ? '0' : theme.constants.spacerMd2,
      paddingLeft: leftDisabled ? '0' : theme.constants.spacerMd2,
      paddingRight: rightDisabled ? '0' : theme.constants.spacerMd2,
    };
  }};

  @media (min-width: ${mediaBreakpointPxSm}) {
    ${({ disabledPadding, size, theme }) => {
      const topDisabled = disabledPadding?.vertical?.all || disabledPadding?.vertical?.top;
      const bottomDisabled = disabledPadding?.vertical?.all || disabledPadding?.vertical?.bottom;
      const leftDisabled = disabledPadding?.horizontal?.all || disabledPadding?.horizontal?.left;
      const rightDisabled = disabledPadding?.horizontal?.all || disabledPadding?.horizontal?.right;

      return {
        paddingTop: topDisabled ? '0' : modalContainerVerticalPadding(size, theme),
        paddingBottom: bottomDisabled ? '0' : modalContainerVerticalPadding(size, theme),
        paddingLeft: leftDisabled ? '0' : modalContainerHorizontalPadding(size, theme),
        paddingRight: rightDisabled ? '0' : modalContainerHorizontalPadding(size, theme),
      };
    }};
  }
`;

function modalContainerWidth(size: ModalSize) {
  switch (size) {
    case 'sm':
      return '23.5rem';
    case 'md':
      return '30.125rem';
    case 'lg':
      return '37.5rem';
    case 'xl':
      return '45rem';
  }
}

export function modalContainerHorizontalPadding(size: ModalSize, theme: DefaultTheme) {
  switch (size) {
    case 'sm':
      return theme.constants.spacerMd3;
    case 'md':
    case 'lg':
    case 'xl':
      return theme.constants.spacerLg3;
  }
}

export const modalContainerVerticalPadding = (size: ModalSize, theme: DefaultTheme) => {
  switch (size) {
    case 'sm':
      return theme.constants.spacerMd3;
    case 'md':
    case 'lg':
    case 'xl':
      return theme.constants.spacerLg1;
  }
};

type DisabledPadding = {
  vertical?: { all?: boolean; top?: boolean; bottom?: boolean };
  horizontal?: { all?: boolean; left?: boolean; right?: boolean };
};

const CoreModal = ({
  children,
  onCloseRequest,
  headerImage,
  heapModalName = 'modal' as HeapModalName,
  desktopSize = 'lg',
  isStaticModal,
  isBlurShadedBackdrop,
  disabledPadding,
  closeIconAriaLabel,
  visible = true,
}: Props) => {
  const FocusLock = process.env.STORYBOOK_ENV === 'true' ? React.Fragment : ReactFocusLock;

  useEffect(() => {
    // Leverages existing functionality to freeze the background while the modal is open
    const body = getBody();
    if (!body) {
      return;
    }

    body.classList.add('modal-open');
    return () => {
      body.classList.remove('modal-open');
    };
  }, []);

  useKeyPress('Escape', isStaticModal ? () => ({}) : onCloseRequest);

  return (
    <Portal>
      {isBlurShadedBackdrop ? (
        <ShadedBlurBackdrop className='modal-backdrop' visible={visible} />
      ) : (
        <ShadedBackdrop className='modal-backdrop' visible={visible} />
      )}
      <ContentBackdrop visible={visible}>
        <Padded aria-modal='true' role='dialog'>
          <FocusLock>
            <Dialog onClick={(e) => e.stopPropagation()} size={desktopSize}>
              <ModalContainer
                className={heapModalName}
                headerImage={headerImage}
                id='modal-container'
              >
                {headerImage && (
                  <HeaderImage headerImage={headerImage}>
                    <img alt='headerImage' src={headerImage} />
                  </HeaderImage>
                )}
                <PaddedContainer disabledPadding={disabledPadding} size={desktopSize}>
                  {children}
                </PaddedContainer>
                {!isStaticModal && (
                  <RightAlignedContainer>
                    <PaddedIcon
                      ariaLabel={closeIconAriaLabel}
                      className={`heap-close-${heapModalName}`}
                      headerImage={headerImage}
                      name='times'
                      onClick={onCloseRequest}
                    />
                  </RightAlignedContainer>
                )}
              </ModalContainer>
            </Dialog>
          </FocusLock>
        </Padded>
      </ContentBackdrop>
    </Portal>
  );
};

function getBody() {
  const bodyTags = document.getElementsByTagName('body');
  if (bodyTags.length !== 1) {
    return null;
  }

  return bodyTags[0];
}

export default CoreModal;
