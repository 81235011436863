import styled from 'styled-components';

import { mediaBreakpointPxSm } from '../../../../../styled/Breakpoint';

const ModalFooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ theme: { constants } }) => constants.spacerSm3};
  justify-content: flex-end;

  @media (min-width: ${mediaBreakpointPxSm}) {
    padding-top: ${({ theme: { constants } }) => constants.spacerMd2};
    flex-direction: row;
    justify-content: flex-end;
  }
`;

export default ModalFooterContainer;
