import styled, { css, keyframes } from 'styled-components';

import { mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import { fontMd3 } from '../../../../styled/TypeSystem';
import DefaultButton from '../../../buttons/DefaultButton';

export const Title = styled.h1`
  margin: 0;
  color: ${({ theme: { vars } }) => vars.textDefault};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontMd3};
`;

export const MainButtonWrapper = styled.span`
  width: 100%;
  margin-top: ${({ theme: { constants } }) => constants.spacerMd2};

  @media (min-width: ${mediaBreakpointPxSm}) {
    width: auto;
    margin-top: 0;
    margin-left: ${({ theme: { constants } }) => constants.spacerMd2};
  }
`;

const fillAnimation = keyframes`
  0% { background-size: 0 100%; }
  100% { background-size: 100% 100%; }
`;

export const StyledFillableButton = styled(DefaultButton)<{ animationTime?: number }>`
  ${({ animationTime, theme: { vars } }) =>
    !!animationTime &&
    css`
      background-image: linear-gradient(
        to right,
        ${vars.accentPrimaryDefault},
        ${vars.accentPrimaryDefault}
      );
      background-repeat: no-repeat;
      background-size: 0 100%;

      &:disabled {
        animation: ${fillAnimation} ${animationTime}ms linear forwards;
        background-color: ${vars.accentSubdued4};
        border-color: ${vars.accentSubdued4};
        color: ${vars.textSurface};
      }
    `}
`;

export const TertiaryActionElementWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  margin-bottom: ${({ theme: { constants } }) => constants.spacerMd2};

  @media (min-width: ${mediaBreakpointPxSm}) {
    width: auto;
    margin-bottom: 0;
    margin-right: ${({ theme: { constants } }) => constants.spacerMd2};
  }
`;
