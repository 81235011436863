export type HeapModalName =
  | 'abandon-people-assigned-modal'
  | 'account-past-due-modal'
  | 'add-delegation-group-card-modal'
  | 'add-delegation-user-card-modal'
  | 'add-teammate-modal'
  | `addon-${string}-cancellation-modal`
  | 'ai-feedback-modal'
  | 'assign-on-publish-modal'
  | 'assign-subjects-to-user-modal'
  | 'avatar-upload-modal'
  | 'billing-history-modal'
  | 'board-manage-modal'
  | 'bulk-content-archive-modal'
  | 'bulk-content-delete-modal'
  | 'bulk-content-export-modal'
  | 'bulk-content-move-modal'
  | 'bulk-content-move-esig-modal'
  | 'bulk-content-unarchive-modal'
  | 'bulk-archive-modal'
  | 'bulk-export-modal'
  | 'bulk-unarchive-modal'
  | 'bulk-unarchive-upgrade-modal'
  | 'bulk-update-permissions-modal'
  | 'bulk-user-deactivate-payroll-modal'
  | 'bulk-user-upload-modal'
  | 'bulk-user-upload-payroll-modal'
  | 'bulk-user-upload-payroll-success-modal'
  | 'cancel-bulk-upload-modal'
  | 'cancel-draft-confirmation-modal'
  | 'cant-move-responsibility-modal'
  | 'compose-step-modal'
  | 'compose-terms-modal'
  | 'confirmation-out-of-seats-modal'
  | 'confirm-csv-modal'
  | 'confirm-delete-board-modal'
  | 'connect-sso-integration-modal'
  | 'create-and-select-group-delegation-modal'
  | 'create-group-kind-modal'
  | 'create-group-modal'
  | 'create-group-name-modal'
  | 'curriculum-description-modal'
  | 'curriculum-complete-modal'
  | 'curriculum-modal'
  | 'curriculum-update-modal'
  | 'cx-implementation-scheduler-modal'
  | 'delegation-planner-celebratory-modal'
  | 'delete-bio-modal'
  | 'delete-curriculum-confirmation-modal'
  | 'delete-curriculum-element-modal'
  | 'delete-group-confirmation-modal'
  | 'delete-subject-modal'
  | 'delete-survey-option-modal'
  | 'delete-survey-question-modal'
  | 'delete-user-confirmation-modal'
  | 'deprecated-manage-step-external-service-link-modal'
  | 'deprecated-restrict-move-curriculum-modal'
  | 'detailed-responsibility-modal'
  | 'disconnect-sso-modal'
  | 'document-import-modal'
  | 'download-csv-modal'
  | 'duplicate-modal'
  | 'duplicate-public-curriculum-modal'
  | 'duplicate-subject-modal'
  | 'edit-group-modal'
  | 'edit-teammate-modal'
  | 'e-signature-modal'
  | 'email-csv-modal'
  | 'export-courses-modal'
  | 'export-pdf-modal'
  | 'group-description-modal'
  | 'group-members-modal'
  | 'group-managers-modal'
  | 'group-read-modal'
  | 'logo-update-modal'
  | 'manage-followed-users-modal'
  | 'manage-group-content-modal'
  | 'manage-step-external-service-link-modal'
  | 'mobile-nudge-desktop-overlay'
  | 'mobile-nudge-native-app-overlay'
  | 'modal'
  | 'move-curriculum-modal'
  | 'move-responsibility-confirmation-modal'
  | 'move-to-signing-step-confirmation-modal'
  | 'move-step-modal'
  | 'notify-content-owner-modal'
  | 'people-assigned-modal'
  | 'people-confirm-assigned-modal'
  | 'privacy-service-modal'
  | 'public-share-modal'
  | 'publish-curriculum-modal'
  | 'record-video-answer-confirmation-modal'
  | 'reached-e-signature-usage-limit-modal'
  | 'reactivated-account-celebration-modal'
  | 'recover-content-modal'
  | 'refer-a-friend-modal'
  | 'refresh-public-curriculum-modal'
  | 'reject-content-modal'
  | 'remove-account-executive-modal'
  | 'remove-teammate-modal'
  | 'remove-group-member-confirmation-modal'
  | 'replace-root-modal'
  | 'reports-to-conflict-modal'
  | 'request-access-modal'
  | 'reset-completion-e-signature-modal'
  | 'responsibility-details-modal'
  | 'restrict-leaving-signing-step-modal'
  | 'restrict-move-curriculum-modal'
  | 'save-capture-to-step-modal'
  | 'send-all-invites-modal'
  | 'send-all-invites-out-of-seats-modal'
  | 'share-changes-modal'
  | 'smart-test-loading-modal'
  | 'smart-test-modal'
  | 'suggested-role-names-modal-v1'
  | 'suggestions-responsibilities-modal'
  | 'supershare-modal'
  | 'survey-modal'
  | 'task-modal'
  | 'templates-download-modal'
  | 'terms-service-modal'
  | 'topic-signed-and-complete-modal'
  | `${'top-nav-' | ''}suggested-role-names-modal`
  | 'top-nav-group-confirmation-modal'
  | 'toggle-users-outline-table-columns-modal'
  | 'teammate-confirmation-modal'
  | 'trial-ended-modal'
  | 'unable-to-save-capture-confirmation-modal'
  | 'update-password-modal'
  | 'update-payment-method-modal'
  | 'upgrade-plan-modal'
  | 'view-sso-integration-modal'
  | 'video-settings-overlay'
  | 'video-modal';

enum TaskAction {
  alt = 'alt',
  cancel = 'cancel',
  submit = 'submit',
}

export type HeapModalActionId = `heap-${TaskAction}-action-${HeapModalName}`;

export type HeapModalAltActionId = `heap-${TaskAction.alt}-action-${HeapModalName}`;
export type HeapModalCancelActionId = `heap-${TaskAction.cancel}-action-${HeapModalName}`;
type HeapModalSubmitActionId = `heap-${TaskAction.submit}-action-${HeapModalName}`;

export function heapModalNameActionIds(name: HeapModalName) {
  return {
    alt: `heap-${TaskAction.alt}-action-${name}` as HeapModalAltActionId,
    cancel: `heap-${TaskAction.cancel}-action-${name}` as HeapModalCancelActionId,
    submit: `heap-${TaskAction.submit}-action-${name}` as HeapModalSubmitActionId,
  };
}

interface TaskTracking {
  heapModalName: HeapModalName;
}

export default TaskTracking;
