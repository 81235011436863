import { useCallback } from 'react';
import { AccountState } from 'types/AccountState';

import { CurrentUser } from '../types/CurrentUser';

export default function useHeap(user: CurrentUser | undefined, account: AccountState | undefined) {
  const account_status = account?.status;
  const is_group_manager = user?.managesAnyGroup;
  const userId = user?.id;
  const email = user?.email;
  const accountStatus = account?.status;
  const accountId = account?.id;
  const loggedInFromSaas = account?.loggedInFromSaas;
  const sdkPresent = window.heap;
  const heap = window.heap;

  const initHeap = useCallback(() => {
    if (loggedInFromSaas) {
      console.log('Logged in from Admin. Skipping...');
      return;
    }

    if (!sdkPresent) {
      console.log('Heap SDK not present. Skipping...');
      return;
    }

    try {
      heap.addEventProperties({ account_status, is_group_manager });
    } catch (error) {
      console.error('Heap Init Error', error.response);
    }
  }, [loggedInFromSaas, sdkPresent, heap, account_status, is_group_manager]);

  const identify = useCallback(() => {
    if (
      loggedInFromSaas ||
      !sdkPresent ||
      !user ||
      !user.analyticsUserProperties ||
      !user.analyticsUserProperties.userId
    ) {
      return;
    }

    const props = { ...user.analyticsUserProperties };
    try {
      heap.identify(props);
    } catch (error) {
      console.error('Heap Identify error', error.response);
    }
  }, [loggedInFromSaas, heap, user, sdkPresent]);

  const track = useCallback(
    (eventName: string, props: object = {}) => {
      if (loggedInFromSaas) {
        console.log('Logged in from Admin. Skipping...');
        return;
      }

      if (!sdkPresent) {
        console.log('Heap SDK not present. Skipping...');
        return;
      }

      try {
        heap.track(eventName, {
          ...props,
          user_id: userId,
          account_status: accountStatus,
          email,
          accountId,
        });
      } catch (error) {
        console.error('Heap Track error', error.response);
      }
    },
    [loggedInFromSaas, sdkPresent, heap, userId, accountStatus, email, accountId]
  );

  return { initHeap, identify, track };
}
