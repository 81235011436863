import { HtEventsBrowser } from '@ht-sdks/events-sdk-js-browser';

import { AnalyticsAccountProperties } from '../../types/AnalyticsAccountProperties';
import { AnalyticsUserProperties } from '../../types/AnalyticsUserProperties';

interface HightouchOpts {
  properties?: object | undefined;
  context?: object | undefined;
}

export const HightouchClient = HtEventsBrowser;
export type HightouchClient = HtEventsBrowser;

export default class HightouchEvent {
  static track(client: HightouchClient, event: string, opts?: HightouchOpts) {
    if (client) {
      const properties = opts?.properties;
      const context = opts?.context;

      this.syncAnonymousId(client);
      client.track(event, properties, { context });
    }

    // Log analytics events to the console in development to enable easier testing/development of analytics events.
    if (process.env.NODE_ENV === 'development') {
      console.group('HightouchEvent');
      console.log(`event: ${event}`);
      if (opts) {
        console.log(opts);
      }
      console.groupEnd();
    }
  }

  static page(
    client: HightouchClient,
    name: string,
    props: { split: { account: number }; fromSpaPage: boolean } | { fromSpaPage: boolean }
  ) {
    if (client) {
      // This is needed for services like Split which do not ingest page events
      //  (or in the case of Split...do not associate these events with accounts)
      // This is commented out as it's expensive to run and we're not currently using it.
      // Uncomment to use.
      // window.analytics.track('page_view', { ...props, name });
      this.syncAnonymousId(client);
      client.page(name, props);
    } else {
      console.log('analytics not defined');
    }
  }

  static identify(client: HightouchClient, props: AnalyticsUserProperties) {
    if (client) {
      this.storeUserId(props.userId);
      this.syncAnonymousId(client);
      client.identify(props.userId.toString(), props);
    } else {
      console.log('analytics not defined');
    }
  }

  static group(
    client: HightouchClient,
    accountId: number | undefined,
    props: AnalyticsAccountProperties
  ) {
    if (client) {
      this.syncAnonymousId(client);
      client.group(accountId?.toString(), props);
    } else {
      console.log('analytics not defined');
    }
  }

  static reset(client: HightouchClient) {
    if (client) {
      client.reset();
    } else {
      console.log('analytics not defined');
    }
  }

  // Private
  static storeUserId(userId: number) {
    document.cookie = `ajs_user_id=${userId}`;
    localStorage.setItem('ajs_user_id', userId.toString());
  }

  static async syncAnonymousId(client: HightouchClient) {
    const localStorageAnonymousId = localStorage.getItem('common_anonymous_id');
    if (localStorageAnonymousId) {
      localStorage.setItem('ajs_anonymous_id', localStorageAnonymousId);
      client.setAnonymousId(localStorageAnonymousId);
    } else if (client.user) {
      const user = await client.user();
      const anonymousId = user.anonymousId();
      if (anonymousId) {
        localStorage.setItem('common_anonymous_id', anonymousId.toString());
      }
    }
  }
}
