import React from 'react';
import styled from 'styled-components';

import Icon from './display/icons/Icon';

const LoadingContainerDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingContainer = () => {
  return (
    <LoadingContainerDiv>
      <Icon name='spinner-third' spinSpeed='fast' weight='solid' />
    </LoadingContainerDiv>
  );
};

export default LoadingContainer;
