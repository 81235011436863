import { useCallback, useMemo } from 'react';

import HightouchEvent, { HightouchClient } from '../lib/hightouch_events';
import { AccountState } from '../types/AccountState';
import { CurrentUser } from '../types/CurrentUser';
import usePublicConfigs from './usePublicConfigs';

export default function useHightouch(
  user: CurrentUser | undefined,
  account: AccountState | undefined
) {
  const { configs } = usePublicConfigs();
  const writeKey = configs['HIGHTOUCH_WRITE_KEY_CLIENT'];
  const apiHost = configs['HIGHTOUCH_HOST'];
  const userId = user?.id;
  const email = user?.email;
  const accountStatus = account?.status;
  const accountId = account?.id;
  const loggedInFromSaas = account?.loggedInFromSaas;
  const shouldSkip = loggedInFromSaas || !writeKey;

  const client = useMemo<HightouchClient>(() => {
    return HightouchClient.load({ writeKey }, { apiHost });
  }, [apiHost, writeKey]);

  const track = useCallback(
    (eventName: string, props: object = {}) => {
      if (shouldSkip) {
        return;
      }
      HightouchEvent.track(client, eventName, {
        properties: {
          ...props,
          user_id: userId,
          account_status: accountStatus,
          email,
        },
        context: {
          groupId: accountId,
        },
      });
    },
    [userId, accountId, accountStatus, email, shouldSkip, client]
  );

  const page = useCallback(
    (name: string) => {
      if (shouldSkip) {
        return;
      }
      const referrer = document.referrer;
      const props = accountId
        ? { split: [{ account: accountId }], fromSpaPage: true, referrer }
        : { fromSpaPage: true, referrer };

      HightouchEvent.page(client, name, props);
    },
    [shouldSkip, accountId, client]
  );

  const identify = useCallback(() => {
    if (
      shouldSkip ||
      !user ||
      !user.analyticsUserProperties ||
      !user.analyticsUserProperties.userId
    ) {
      return;
    }
    const props = { ...user.analyticsUserProperties };
    HightouchEvent.identify(client, props);
  }, [shouldSkip, user, client]);

  const group = useCallback(() => {
    if (shouldSkip || account === undefined) {
      return;
    }
    const props = { ...account.analyticsAccountProperties };
    HightouchEvent.group(client, accountId, props);
  }, [shouldSkip, account, accountId, client]);

  const reset = useCallback(() => {
    if (shouldSkip) {
      return;
    }
    HightouchEvent.reset(client);
  }, [shouldSkip, client]);

  return {
    analyticsPageLoadEvent: page,
    analyticsIdentify: identify,
    analyticsGroup: group,
    analyticsReset: reset,
    analyticsTrack: track,
  };
}
