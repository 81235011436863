import { useEffect } from 'react';

const useKeyPress = (key: string, actionName: (() => void) | null) => {
  useEffect(() => {
    const event = (e: KeyboardEvent) => {
      if (e.key === key && actionName) {
        actionName();
      }
    };
    window.addEventListener('keydown', event);
    return () => window.removeEventListener('keydown', event);
  }, [key, actionName]);
};

export default useKeyPress;
