import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Omit } from 'react-beautiful-dnd';

import useWindowResize from '../../../../../hooks/useWindowResize';
import initTranslations from '../../../../../lib/initTranslations';
import { heapModalNameActionIds } from '../../../../../types/TaskTracking';
import { mediaBreakpointSm } from '../../../../styled/Breakpoint';
import DefaultButton from '../../../buttons/DefaultButton';
import CoreModal, { ModalProps } from '../../../core/CoreModal';
import ModalBodyContainer from '../../../core/Layout/ModalContainers/ModalBodyContainer';
import ModalFooterContainer from '../../../core/Layout/ModalContainers/ModalFooterContainer';
import ModalHeaderContainer from '../../../core/Layout/ModalContainers/ModalHeaderContainer';
import P from '../../../text/P';
import {
  MainButtonWrapper,
  StyledFillableButton,
  TertiaryActionElementWrapper,
  Title,
} from './styles';

export type PrimaryButtonProps =
  | { hasPrimaryButton: false; actionText?: never }
  | { hasPrimaryButton?: boolean; actionText: string };

export type SecondaryButtonProps =
  | { hasSecondaryButton: false; secondaryButtonText?: never }
  | { hasSecondaryButton?: boolean; secondaryButtonText?: string };

export type ConfirmationModalProps = Omit<ModalProps, 'children' | 'closeIconAriaLabel'> & {
  title: string;
  message: string;
  processing: boolean;
  actionFunction: () => Promise<void> | void;
  closeIconAriaLabel?: string;
  primaryButtonAnimationTimeout?: number;
  tertiaryActionElement?: ReactNode;
};

const t = initTranslations('confirmation_modal');

export type Props = ConfirmationModalProps & PrimaryButtonProps & SecondaryButtonProps;
const ConfirmationModal = (props: Props): ReactElement => {
  const {
    title,
    message,
    actionText,
    processing,
    actionFunction,
    secondaryButtonText,
    hasSecondaryButton = true,
    hasPrimaryButton = true,
    closeIconAriaLabel = t('aria_label_close'),
    primaryButtonAnimationTimeout,
    tertiaryActionElement,
    ...propsToPass
  } = props;

  const heapActionIds = heapModalNameActionIds(props.heapModalName);
  const { width } = useWindowResize();
  const [isDisabledForAnimation, setIsDisabledForAnimation] = useState(
    !!primaryButtonAnimationTimeout
  );

  useEffect(() => {
    if (!!primaryButtonAnimationTimeout) {
      setIsDisabledForAnimation(true);
      const timer = setTimeout(
        () => setIsDisabledForAnimation(false),
        primaryButtonAnimationTimeout
      );

      return () => clearTimeout(timer);
    }
  }, [primaryButtonAnimationTimeout]);

  return (
    <CoreModal {...propsToPass} closeIconAriaLabel={closeIconAriaLabel}>
      <ModalHeaderContainer>
        <Title>{title}</Title>
      </ModalHeaderContainer>
      <ModalBodyContainer>
        <P text={message} />
      </ModalBodyContainer>
      <ModalFooterContainer>
        {!isDisabledForAnimation && tertiaryActionElement && (
          <TertiaryActionElementWrapper>{tertiaryActionElement}</TertiaryActionElementWrapper>
        )}
        {hasSecondaryButton && !isDisabledForAnimation && (
          <DefaultButton
            buttonType='secondary'
            disabled={processing}
            fullWidth={width < mediaBreakpointSm}
            id={heapActionIds.cancel}
            onClick={() => !processing && props.onCloseRequest()}
            text={secondaryButtonText || t('cancel')}
          />
        )}
        {hasPrimaryButton && actionText && (
          <MainButtonWrapper>
            <StyledFillableButton
              animationTime={primaryButtonAnimationTimeout}
              buttonType='primary'
              disabled={isDisabledForAnimation}
              fullWidth={width < mediaBreakpointSm}
              id={heapActionIds.submit}
              loading={processing}
              onClick={() => !processing && actionFunction()}
              text={actionText}
            />
          </MainButtonWrapper>
        )}
      </ModalFooterContainer>
    </CoreModal>
  );
};

export default ConfirmationModal;
